import request from '@/utils/request';

export function create(data) {
  return request({
    url: 'v1/imageOption',
    method: 'post',
    data,
  });
}

export function fetchByType(params) {
  return request({
    url: 'v1/imageOption',
    method: 'get',
    params,
  });
}
