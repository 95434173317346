<template>
  <el-card class="form-container" shadow="never">
    <el-form :model="formData" ref="formData" label-width="150px">
      <el-form-item label="Brand Name(中文):" prop="brandName.chs">
        <el-input v-model="formData.brandName.chs"></el-input>
      </el-form-item>
      <el-form-item label="Brand Name(English):" prop="brandName.chs">
        <el-input v-model="formData.brandName.eng"></el-input>
      </el-form-item>
      <el-form-item label="Caption(中文):" prop="brandName.chs">
        <el-input v-model="formData.caption.chs"></el-input>
      </el-form-item>
      <el-form-item label="Caption(English):" prop="brandName.chs">
        <el-input v-model="formData.caption.eng"></el-input>
      </el-form-item>
      <el-form-item label="Merchants" prop="merchantIds">
        <el-select v-model="formData.merchantIds" multiple>
          <el-option v-for="item in merchantOptions" :label="item.name" :key="item.id" :value="item.id">
          </el-option>
        </el-select>
        <!-- <el-checkbox label="Admin Edit" v-model="formData.merchantsEditProperties.adminModify"></el-checkbox>
        <el-checkbox label="Employee Edit" v-model="formData.merchantsEditProperties.employeeModify"></el-checkbox> -->
      </el-form-item>
      <el-form-item label="Start Date" prop="startDate">
        <el-date-picker v-model="formData.startDate" type="datetime" placeholder="Start Date"
          value-format="yyyy-MM-dd HH:mm:ss" :disabled="isEdit"></el-date-picker>
        <!-- <el-checkbox label="Admin Edit" v-model="formData.startDateEditProperties.adminModify"></el-checkbox>
        <el-checkbox label="Employee Edit" v-model="formData.startDateEditProperties.employeeModify"></el-checkbox> -->
      </el-form-item>
      <el-form-item label="End Date" prop="endDate">
        <el-date-picker v-model="formData.endDate" type="datetime" placeholder="End Date"
          value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        <!-- <el-checkbox label="Admin Edit" v-model="formData.endDateEditProperties.adminModify"></el-checkbox>
        <el-checkbox label="Employee Edit" v-model="formData.endDateEditProperties.employeeModify"></el-checkbox> -->
      </el-form-item>
      <el-form-item label="Merchant Logo" prop="merchantLogo">
        <single-upload v-model="formData.merchantLogo"></single-upload>
        <!-- <el-checkbox label="Admin Edit" v-model="formData.merchantLogoEditProperties.adminModify"></el-checkbox>
        <el-checkbox label="Employee Edit" v-model="formData.merchantLogoEditProperties.employeeModify"></el-checkbox> -->
      </el-form-item>
      <el-form-item label="Transparent Logo" prop="transparentLogo">
        <single-upload v-model="formData.transparentLogo"></single-upload>
      </el-form-item>
      <el-form-item label="Card BG" prop="cardBg">
        <!-- <single-upload v-model="formData.cardBg"></single-upload> -->
        <!-- <el-checkbox label="Admin Edit" v-model="formData.cardBgEditProperties.adminModify"></el-checkbox>
        <el-checkbox label="Employee Edit" v-model="formData.cardBgEditProperties.employeeModify"></el-checkbox> -->
        <el-select v-model="formData.cardBg">
          <el-option v-for="item in cardBgOptions" :key="item.url" :label="item.name" :value="item.url"></el-option>
        </el-select>
        <p>
          <img :src="formData.cardBg" v-if="formData.cardBg">
        </p>
      </el-form-item>
      <el-form-item label="Type" prop="type">
        <el-select v-model="formData.type" @change="fillDefaultTypeParams" :disabled="isEdit">
          <el-option v-for="item in typeOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Status" prop="status">
        <el-select v-model="formData.status">
          <el-option v-for="item in statusOptions" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <!-- <el-checkbox label="Admin Edit" v-model="formData.statusEditProperties.adminModify"></el-checkbox>
        <el-checkbox label="Employee Edit" v-model="formData.statusEditProperties.employeeModify"></el-checkbox> -->
      </el-form-item>
      <el-form-item label="Benefits" prop="benefits">
        <div v-for="(item, index) in formData.benefits">
          <el-card>
            <el-form-item label="Title(中文)">
              <el-input v-model="item.title.chs"></el-input>
            </el-form-item>
            <el-form-item label="Title(English)">
              <el-input v-model="item.title.eng"></el-input>
            </el-form-item>
            <el-form-item label="Logo">
              <!-- <single-upload v-model="item.logo"></single-upload> -->
              <el-select v-model="item.logo">
                <el-option v-for="item in benefitOptions" :key="item.url" :label="item.name"
                  :value="item.url"></el-option>
              </el-select>
              <p>
                <img :src="item.logo" v-if="item.logo">
              </p>
              <el-button @click="formData.benefits.splice(index, 1)">Delete</el-button>
            </el-form-item>
          </el-card>
        </div>
        <el-button @click="formData.benefits.push(JSON.parse(JSON.stringify(defaultBenefit)))">Add</el-button>
      </el-form-item>
      <el-form-item label="Rules" prop="rules">
        <div v-for="(item, index) in formData.rules">
          <el-card>
            <el-form-item label="Title(中文)">
              <el-input v-model="item.title.chs"></el-input>
            </el-form-item>
            <el-form-item label="Title(English)">
              <el-input v-model="item.title.eng"></el-input>
            </el-form-item>
            <el-form-item label="Content(中文)">
              <el-input v-model="item.content.chs"></el-input>
            </el-form-item>
            <el-form-item label="Content(English)">
              <el-input v-model="item.content.eng"></el-input>
              <el-button label="delete" @click="formData.rules.splice(index, 1)">Delete</el-button>
            </el-form-item>
          </el-card>
        </div>
        <el-button @click="formData.rules.push(JSON.parse(JSON.stringify(defaultRule)))">Add</el-button>
      </el-form-item>
      <div v-if="formData.type == 'CASHBACK'">
        <el-form-item label="Amount To Points Ratio" prop="cashbackProperties.amountToPtsRatio">
          <el-input v-model="formData.cashbackProperties.amountToPtsRatio" :disabled="isEdit"></el-input>
          <!-- <el-checkbox label="Admin Edit"
            v-model="formData.cashbackProperties.amountToPtsRatioEditProperties.adminModify"></el-checkbox>
          <el-checkbox label="Employee Edit"
            v-model="formData.cashbackProperties.amountToPtsRatioEditProperties.employeeModify"></el-checkbox> -->
        </el-form-item>
        <el-form-item label="Points To Cashback Ratio" prop="cashbackProperties.ptsToCashbackRatio">
          <el-input v-model="formData.cashbackProperties.ptsToCashbackRatio" :disabled="isEdit"></el-input>
          <!-- <el-checkbox label="Admin Edit"
            v-model="formData.cashbackProperties.ptsToCashbackRatioEditProperties.adminModify"></el-checkbox>
          <el-checkbox label="Employee Edit"
            v-model="formData.cashbackProperties.ptsToCashbackRatioEditProperties.employeeModify"></el-checkbox> -->
        </el-form-item>
        <el-form-item label="Threshold" prop="cashbackProperties.threshold">
          <el-input v-model="formData.cashbackProperties.threshold" :disabled="isEdit"></el-input>
          <!-- <el-checkbox label="Admin Edit" v-model="formData.cashbackProperties.thresholdEditProperties.adminModify">
          </el-checkbox>
          <el-checkbox label="Employee Edit"
            v-model="formData.cashbackProperties.thresholdEditProperties.employeeModify"></el-checkbox> -->
        </el-form-item>
        <el-form-item label="Max Redeem Points" prop="cashbackProperties.maxRedeem">
          <el-input v-model="formData.cashbackProperties.maxRedeem" :disabled="isEdit"></el-input>
          <!-- <el-checkbox label="Admin Edit" v-model="formData.cashbackProperties.maxRedeemEditProperties.adminModify">
          </el-checkbox>
          <el-checkbox label="Employee Edit"
            v-model="formData.cashbackProperties.maxRedeemEditProperties.employeeModify"></el-checkbox> -->
        </el-form-item>
      </div>
      <div v-if="formData.type == 'POINTS_CARD'">
        <el-form-item label="Equivalency Value" prop="pointsCardProperties.equivalencyValue">
          <el-input v-model="formData.pointsCardProperties.equivalencyValue" :disabled="isEdit"></el-input>
          <!-- <el-checkbox label="Admin Edit"
            v-model="formData.pointsCardProperties.equivalencyValueEditProperties.adminModify"></el-checkbox>
          <el-checkbox label="Employee Edit"
            v-model="formData.pointsCardProperties.equivalencyValueEditProperties.employeeModify"></el-checkbox> -->
        </el-form-item>
        <el-form-item label="Gifts" prop="gifts">
          <div v-for="item in formData.gifts">
            <el-card>
              <el-form-item label="Name(中文)">
                <el-input v-model="item.name.chs" :disabled="item.id"></el-input>
              </el-form-item>
              <el-form-item label="Name(English)">
                <el-input v-model="item.name.eng" :disabled="item.id"></el-input>
              </el-form-item>
              <el-form-item label="Points">
                <el-input v-model="item.points" :disabled="item.id"></el-input>
              </el-form-item>
              <el-checkbox label="Active" v-model="item.active"></el-checkbox>
            </el-card>
          </div>
          <el-button @click="formData.gifts.push(JSON.parse(JSON.stringify(defaultGift)))">Add</el-button>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="onSubmit()">提交</el-button>
        <el-button v-if="!isEdit" @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload';
import { create as createMembership, fetchDetail as fetchMembership } from '@/api/membership';
import { fetchOptions as fetchMerchantOptions } from '@/api/merchant';
import { fetchByType as fetchImageOptionsByType } from '../../api/imageOption';

const defaultCashbackProperties = {
  amountToPtsRatio: null,
  // amountToPtsRatioEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  ptsToCashbackRatio: null,
  // ptsToCashbackRatioEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  threshold: null,
  // thresholdEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  maxRedeem: null,
  // maxRedeemEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
};
const defaultPointsCardProperties = {
  equivalencyValue: null,
  // equivalencyValueEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
};
const defaultGift = {
  id: null,
  name: {
    chs: null,
    eng: null,
  },
  points: null,
  active: true,
};
const defaultFormData = {
  id: null,
  brandName: {
    chs: null,
    eng: null,
  },
  caption: {
    chs: null,
    eng: null,
  },
  merchantIds: [],
  startDate: null,
  endDate: null,
  merchantLogo: null,
  transparentLogo: null,
  cardBg: null,
  type: null,
  status: 'CREATED',
  benefits: [],
  rules: [],
  cashbackProperties: defaultCashbackProperties,
  pointsCardProperties: defaultPointsCardProperties,
  gifts: [],
  // merchantsEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  // startDateEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  // endDateEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  // merchantLogoEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  // cardBgEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  // statusEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  // benefitsEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  // rulesEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
  // statusEditProperties: {
  //   adminModify: false,
  //   employeeModify: false,
  // },
};
const defaultBenefit = {
  title: {
    chs: '',
    eng: '',
  },
  logo: null,
};
const defaultRule = {
  title: {
    chs: '',
    eng: '',
  },
  content: {
    chs: '',
    eng: '',
  },
};
export default {
  name: 'MembershipDetail',
  components: { SingleUpload },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.isEdit) {
      fetchMembership(this.$route.query.id).then((response) => {
        this.formData = response.data;
      });
    }
    fetchMerchantOptions().then((response) => {
      this.merchantOptions = response.data;
    });
    fetchImageOptionsByType({ type: 'membership_benefit_thumbnail' }).then((response) => {
      this.benefitOptions = response.data;
    });
    fetchImageOptionsByType({ type: 'membership_cardBg' }).then((response) => {
      this.cardBgOptions = response.data;
    });
  },
  data() {
    return {
      formData: { ...defaultFormData },
      typeOptions: ['CASHBACK', 'POINTS_CARD'],
      statusOptions: ['CREATED', 'PUBLISHED', 'EXPIRED', 'SUSPENDED'],
      merchantOptions: [],
      cardBgOptions: [],
      benefitOptions: [],
      defaultBenefit,
      defaultRule,
      defaultGift,
    };
  },
  methods: {
    resetForm() {
      this.formData = { ...defaultMembership };
    },
    onSubmit() {
      this.$confirm('是否提交数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        createMembership(this.formData).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          setTimeout(() => {
            location.reload();
          }, 1000);
        });
      });
    },
    fillDefaultTypeParams() {
      this.formData.cashbackProperties = defaultCashbackProperties;
      this.formData.pointsCardProperties = defaultPointsCardProperties;
    },
  },
};
</script>
