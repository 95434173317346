import request from '@/utils/request';

export function fetchList(params) {
  return request({
    url: 'v1/membership',
    method: 'get',
    params,
  });
}

export function fetchDetail(id) {
  return request({
    url: `/v1/membership/${id}`,
    method: 'get',
  });
}

export function create(data) {
  return request({
    url: 'v1/membership',
    method: 'post',
    data,
  });
}
