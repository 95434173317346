<template>
  <membership-detail :is-edit="false"></membership-detail>
</template>
<script>
import MembershipDetail from './MembershipDetail';

export default {
  name: 'CreateMembership',
  components: { MembershipDetail },
};
</script>
